
.container-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .card-menu {
    width: 18rem;
  }
  
  .card-img-top-menu {
    height: 200px;
    object-fit: cover;
  }
  
  .card-title-menu {
    font-size: 18px;
    font-weight: bold;
  }
  
  .card-text-menu {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .card-body-menu {
    padding: 20px;
  }
  
  .btn-primary-menu {
    width: 100%;
  }