
body{
  background-color:#17a2b8!important;
}
.container-geral {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #326295;
}

.container-login {
  margin: 60px;
  /* background-color: #FFEFAE; */
  /* background-color: #dadada!important; */
  background-color: #fff!important;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #1d1d1d;
  text-align: center;
}

.logo-container {
  margin-bottom: 25px;
}

.brand-logo-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e6e6e6;
}

.brand-logo {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.login-text {
  margin-bottom: 25px;
}

.form-container {
  width: 400px;
  margin: 0 auto;
}

.form-group {
  text-align: left;
  margin-bottom: 20px;
}

.input-group {
  position: relative;
}

.input-group-text {
  background-color: #f2f2f2;
  border: none;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 16px;
}

.form-control:focus {
  outline: none;
}

.btn-primary {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #F2A900;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #FFEFAE;
}

.mt-120{
  margin-top: -120px;
}
.bg-t{
  background-color: #326295;
  padding: 8px;
  padding-top: 8px;
  width: 43%;
  height: 48%;
  padding-bottom: 10px;
}
.logo-linea{
  width: 75%;
}
.container-login {
  margin: 30px;
  background-color: #FFEFAE;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #1d1d1d;
  text-align: center;
}

.form-container {
  width: 370px;
  margin: 0 auto;
}

.input-login {
  width: 70%;
}

.form-group .input-group {
  width: 100%;
  margin: 0 auto;
}
.form-container form {width: 70%;
    margin-left: 15%;
    padding-bottom: 20px;
}
.input-group-text{
height: 100%;
background-color: #1a2a4a!important;
border-top-right-radius: 0%!important;
border-bottom-right-radius: 0%!important;

}
.btn-primary{
  
background-color: #1a2a4a!important;
border-color: #1a2a4a!important;
}